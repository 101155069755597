@import '../../../scss/index.scss';

.greetings-top {
    padding-left: 20px;
    padding-right: 20px;
    &__left {
        h3 {
            font-weight: 500;
            font-family: 'Roboto', sans-serif;
            margin-bottom: 0px;
            text-transform: capitalize;
            font-size: 20px ;
        }
    }

    &__right {
        p {
            text-align: right;
        }
    }
}
.ant-divider.top-devider {
    border-top: 1px solid lighten($color: #000000, $amount: 50);
    margin-top: 10px;
}

@media only screen and (max-width: $media-breackpoint-up-sm) {
    .greetings-top {
        &__left {
            h2 {
                text-align: center;
            }
        }

        &__right {
            p {
                text-align: right;
            }
        }
    }
}
