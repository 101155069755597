@import '../../../scss/index.scss';

.perfil-form {

    &__container-image {
        text-align: center;
        border-radius: 50%;

    }
   
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        width: 90%;
        margin: 20px;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;

        .perfil-form__deliveryCost {
            margin-top: 30px;
        }
        .perfil-form__container-button {
            width: 600px;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
}