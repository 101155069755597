@import '../../scss/index.scss';
.login {
    &__content {
        align-items: center;
        min-height: 100vh;
        display: flex;
        background-image: linear-gradient(
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
            ),
            url('../../assets/img/png/bg_login.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: bottom;
        justify-content: center;
    }

    &__content-banner {
        background-color: $background-light;
        // max-width: 500px;
        padding: 40px 80px;
        text-align: center;
        width: 500px;
    }

    .login-container {
        justify-content: center;

        h2,
        h1 {
            margin: 0;
            font-weight: 200;
        }

        h3 {
            letter-spacing: 1px;
            font-weight: 400;
            font-family: 'Roboto', sans-serif;
        }
    }

    .ant-form-item-control-input-content {
        text-align: center;
    }
}

/* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {...}

/* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {...}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .login__content-subtitle h1 {
        font-size: 32px;
    }
    .login .ant-spin-container {
        // left: 0;
        // width: 500px;
        // right: 0;
        margin: auto;
        padding: 20px 0;
        // border: 1px solid red;
        // box-shadow: 2px 2px 5px #999;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
