@import '../scss/variables';

.layout-admin {
    // transition: margin-left 0.2s;
    // height: 100vh;

    &__header {
        position: fixed !important;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        padding: 0;
        height: 65px;
        z-index: 1000;
    }

    &__content {
        min-height: calc(100vh - 65px);
        padding: 90px 25px 25px 25px;
    }

    &__footer {
        padding: 20px;
        text-align: right;
    }

    @media only screen and (max-width: $media-breackpoint-up-sm) {
        margin-left: 0 !important;
        &__content {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
.spinner{
    display: block;
  position: fixed;
  z-index: 1031; 
  top: 50%;
  right: 50%; 
}

.ant-layout-sider-zero-width-trigger{
    display: none;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
    overflow-y: scroll;
    height: 90%;
}