@import '../../../scss/index.scss';

.login-form {
    padding: 0 22px;

    .ant-form-item-required {
        font-size: 16px;
        font-weight: 500;
    }

    .login-form-register {
        margin-right: 10px;
    }

    .ant-btn-round {
        width: 80%;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease 0s;
        font-weight: bold;
    }

    .login-form-login {
        background-color: $blue-main;
        border-color: $blue-main;

        &:hover {
            background-color: darken($color: $blue-main, $amount: 10) !important;
            border-color: $blue-main;
        }

        &:focus {
            background-color: lighten($color: $blue-main, $amount: 10);
            background-color: $blue-main;
        }
    }
    .login-form-regresar {
        background-color: #d9534f;
        border-color: #d43f3a;

        &:hover {
            background-color: #c9302c;
            border-color: #ac2925;
        }

        &:focus {
            background-color: #c9302c;
            border-color: #ac2925;
        }
    }

    .ant-input-affix-wrapper {
        border: none;
        border-bottom: 1px solid gray;

        &:hover {
            border-bottom: 2px solid $blue-main;
            font-size: 20px;
        }
    }

    &__content {
        &-right {
            text-align: right;
        }
    }
}
