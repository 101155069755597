@import '../../../scss/index.scss';

.admin-sider {
    position: fixed;
    left: 0;
    top: 65px;
    height: 100vh;
    overflow: auto;
    z-index: 1000;
}

.menu-extra {
    text-align: center;
    position: absolute;
    bottom: 100px;
    width: 100%;
}
.menu-info {
    .ant-typography {
        color: $font-light;
    }
    span {
        color: $font-light;
    }
}
